<template>
  <el-dialog class="course-view-dialog"
             :title="viewTitle ? viewTitle :'预览'"
             :visible.sync="visible"
             :courseViewDialogVisible="courseViewDialogVisible"
             :append-to-body="true"
             :destroy-on-close="true"
             @before-close="handleClose"
             @close="handleClose"
             width="19.53125vw">
    <div class="view-body"
         :style="{ 'overflow-y': isViewShow ? 'hidden' : 'auto' }">
      <div class="dialog-panel"
           v-for="(item,index) in viewData"
           :key="item.id">
        <div class="dialog-panel-title"
             @click="handleContentItem('turnItem', null, null, item, index)">
          <div class="name">
            <div class="line"></div>
            <div class="text">{{ item.contentName }}</div>
          </div>
          <div class="button">
            <img :src="firstPanelTurnIcon"
                 alt=""
                 :class="{ 'turn-icon-class': !item.showFlag }" />
          </div>
        </div>
        <div class="dialog-panel-content"
             v-show="item.showFlag"
             v-if="item.eduCourseThemeCatalogueContentTrees && item.eduCourseThemeCatalogueContentTrees.length > 0">
          <div class="dialog-second-panel"
               v-for="(info,i) in item.eduCourseThemeCatalogueContentTrees"
               :key="info.id">
            <div class="dialog-panel-second-title">{{ info.contentName }}</div>
            <div class="dialog-panel-second-content"
                 v-if="info.contentType == '0'">
              <div class="dialog-content-info"
                   v-if="info.eduCourseThemeContent && info.eduCourseThemeContent.length > 0">
                <div class="content-html"
                     v-html="info.eduCourseThemeContent && info.eduCourseThemeContent[0].content"></div>
              </div>
              <div class="dialog-file-info"
                   v-if="info.eduCourseThemeCatalogueContentFile">
                <div class="dialog-file-item"
                     v-for="fileInfo in info.eduCourseThemeCatalogueContentFile"
                     :key="fileInfo.id"
                     @click="fileView(fileInfo)">
                  <img class="icon"
                       :src="fileIconPDF"
                       alt=""
                       v-if="fileInfo.iconType == 'pdf'" />
                  <img class="icon"
                       :src="fileIconWord"
                       alt=""
                       v-if="fileInfo.iconType == 'doc'" />
                  <img class="icon"
                       :src="fileIconExcel"
                       alt=""
                       v-if="fileInfo.iconType == 'xls'" />
                  <img class="icon"
                       :src="fileIconPPT"
                       alt=""
                       v-if="fileInfo.iconType == 'ppt'" />
                  <div class="title">{{ fileInfo.originalName }}</div>
                </div>
              </div>
            </div>
            <div class="dialog-panel-second-content"
                 v-if="info.contentType == '1'">
              <div class="dialog-video-info"
                   v-if="info.eduCourseThemeCatalogueContentVideo && info.eduCourseThemeCatalogueContentVideo.length > 0">
                <div class="dialog-video-item"
                     v-for="(video,i) in info.eduCourseThemeCatalogueContentVideo"
                     :key="video.id"
                     @click="videoView(video)"
                     :style="{ 'margin-right': (i+1) % 2 == 0 ? '0' : '0.26042vw' }">
                  <img class="video-bg"
                       :src="video.fontImg ? video.fontImg : defaultImg"
                       alt="">
                  <img class="video-play-icon"
                       :src="miniVideoPlayIcon"
                       alt="" />
                </div>
              </div>
            </div>
            <div class="dialog-panel-second-content"
                 v-if="info.contentType == '2'">
              <div class="dialog-activity-type-check"
                   v-show="info.activityListShow">
                <div class="dialog-activity-type-item"
                     :class="{ active: radioInfo.value == info.activityTypeRadio }"
                     v-for="radioInfo in info.activityTypeList"
                     :key="radioInfo.id"
                     @click="handleRadioChange(radioInfo.value, info, i, item, index)">{{ radioInfo.name }}</div>
              </div>
              <div class="dialog-third-panel"
                   v-for="activityInfo in info.activityList"
                   :key="activityInfo.id">
                <div class="dialog-panel-third-title">{{ activityInfo.activityName }}</div>
                <div class="dialog-panel-third-content"
                     v-if="activityInfo.activityContent">
                  <div class="content-html"
                       v-html="activityInfo.activityContent"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-panel-content"
             v-show="item.showFlag"
             v-if="item.eduCourseThemeCatalogueContentTrees && item.eduCourseThemeCatalogueContentTrees.length == 0">
          <div class="content-panel"
               v-if="item.contentType == '0'">
            <div class="dialog-content-info"
                 v-if="item.eduCourseThemeContent && item.eduCourseThemeContent.length > 0">
              <div class="content-html"
                   v-html="item.eduCourseThemeContent && item.eduCourseThemeContent[0].content"></div>
            </div>
            <div class="dialog-file-info"
                 v-if="item.eduCourseThemeCatalogueContentFile">
              <div class="dialog-file-item"
                   v-for="fileInfo in item.eduCourseThemeCatalogueContentFile"
                   :key="fileInfo.id"
                   @click="fileView(fileInfo)">
                <img class="icon"
                     :src="fileIconPDF"
                     alt=""
                     v-if="fileInfo.iconType == 'pdf'" />
                <img class="icon"
                     :src="fileIconWord"
                     alt=""
                     v-if="fileInfo.iconType == 'doc'" />
                <img class="icon"
                     :src="fileIconExcel"
                     alt=""
                     v-if="fileInfo.iconType == 'xls'" />
                <img class="icon"
                     :src="fileIconPPT"
                     alt=""
                     v-if="fileInfo.iconType == 'ppt'" />
                <div class="title">{{ fileInfo.originalName }}</div>
              </div>
            </div>
          </div>
          <div class="content-panel"
               v-if="item.contentType == '1'">
            <div class="dialog-video-info"
                 v-if="item.eduCourseThemeCatalogueContentVideo && item.eduCourseThemeCatalogueContentVideo.length > 0">
              <div class="dialog-video-item"
                   v-for="(video,i) in item.eduCourseThemeCatalogueContentVideo"
                   :key="video.id"
                   @click="videoView(video)"
                   :style="{ 'margin-right': (i+1) % 2 == 0 ? '0' : '0.26042vw' }">
                <img class="video-bg"
                     :src="video.fontImg ? video.fontImg : defaultImg"
                     alt="">
                <img class="video-play-icon"
                     :src="miniVideoPlayIcon"
                     alt="" />
              </div>
            </div>
          </div>
          <div class="content-panel"
               v-if="item.contentType == '2'">
            <div class="dialog-activity-type-check"
                 v-show="item.activityListShow">
              <div class="dialog-activity-type-item"
                   :class="{ active: radioInfo.value == item.activityTypeRadio }"
                   v-for="radioInfo in item.activityTypeList"
                   :key="radioInfo.id"
                   @click="handleRadioChange(radioInfo.value, null, null, item, index)">{{ radioInfo.name }}</div>
            </div>
            <div class="dialog-third-panel"
                 v-for="activityInfo in item.activityList"
                 :key="activityInfo.id">
              <div class="dialog-panel-third-title">{{ activityInfo.activityName }}</div>
              <div class="dialog-panel-third-content"
                   v-if="activityInfo.activityContent">
                <div class="content-html"
                     v-html="activityInfo.activityContent"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-image style="width: 0; height: 0;"
                ref="viewImg"
                :src="viewUrl"
                :preview-src-list="viewUrlList"></el-image>
      <div class="video-view"
           v-if="isViewShow">
        <video :src="videoUrl"
               controls></video>
        <i class="el-icon-close video-view-del-icon"
           @click="handleVideoClose"></i>
      </div>
    </div>
    <div slot="footer"
         class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import firstPanelTurnIcon from "@/assets/img/first-panel-turn-icon.png";
import fileIconPDF from "@/assets/img/file-icon-PDF.png";
import fileIconWord from "@/assets/img/file-icon-word.png";
import fileIconExcel from "@/assets/img/file-icon-excel.png";
import fileIconPPT from "@/assets/img/file-icon-ppt.png";
import miniVideoPlayIcon from "@/assets/img/mini-video-play-icon.png";
import defaultImg from "@/assets/img/default-img.png";
import { getFileViewUrl, exportFile } from "@/utils/fileUtils.js";
import store from '../../store'
export default {
  name: "publishView",
  props: {
    contentId: {
      type: Number,
      default: null
    },
    courseViewDialogVisible: {
      type: Boolean,
      default: false
    },
    viewTitle: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      visible: false, //弹窗标识
      viewData: [], //详情列表

      firstPanelTurnIcon, //折叠按钮
      fileIconPDF, //pdfIcon
      fileIconWord, //wordIcon
      fileIconExcel, //excelIcon
      fileIconPPT, //PPTIcon
      miniVideoPlayIcon, //视频icon
      defaultImg, //默认图片

      videoUrl: "", //视频预览地址
      isViewShow: false, //视频预览div标识
      activityTypeOptions: [],

      fileTypeList: ['png', 'jpg', 'jpeg'], // 文件类型list

      viewUrl: "",
      viewUrlList: [],
    }
  },
  watch: {
    courseViewDialogVisible (val) {
      this.visible = val
      if (val) {
        this.activityTypeOptions = store.state.common.activityTypeList
        console.log(store.state.common.activityTypeList)
        this.$nextTick(() => {
          if (this.viewTitle == '查看资源') {
            this.getViewData()
          } else {
            this.getData()
          }
        })
      }
    },
  },
  methods: {
    getFileViewUrl,
    //获取教学计划内容资源list
    getViewData () {
      console.log(this.contentId)
      let params = { courseThemeCatalogueId: this.contentId }
      this.$api.searchCourseContentCatalogueActivityDetail(params).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.length > 0) {
            res.data.data.map((item, index) => {
              item.showFlag = true;
              if (item.eduCourseThemeCatalogueContentFile && item.eduCourseThemeCatalogueContentFile.length > 0) {
                item.eduCourseThemeCatalogueContentFile.map((fileInfo) => {
                  let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf("."), fileInfo.fileUrl.length);
                  if (type.toLowerCase().indexOf("pdf") != -1) {
                    fileInfo.iconType = "pdf";
                  }
                  if (type.toLowerCase().indexOf("doc") != -1) {
                    fileInfo.iconType = "doc";
                  }
                  if (type.toLowerCase().indexOf("xls") != -1) {
                    fileInfo.iconType = "xls";
                  }
                  if (type.toLowerCase().indexOf("ppt") != -1) {
                    fileInfo.iconType = "ppt";
                  }
                });
              }
              if (item.eduCourseThemeCatalogueContentTrees && item.eduCourseThemeCatalogueContentTrees.length > 0) {
                item.eduCourseThemeCatalogueContentTrees.map((info, i) => {
                  info.showFlag = true;
                  if (info.eduCourseThemeCatalogueContentFile && info.eduCourseThemeCatalogueContentFile.length > 0) {
                    info.eduCourseThemeCatalogueContentFile.map((fileInfo) => {
                      let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf("."), fileInfo.fileUrl.length);
                      if (type.toLowerCase().indexOf("pdf") != -1) {
                        fileInfo.iconType = "pdf";
                      }
                      if (type.toLowerCase().indexOf("doc") != -1) {
                        fileInfo.iconType = "doc";
                      }
                      if (type.toLowerCase().indexOf("xls") != -1) {
                        fileInfo.iconType = "xls";
                      }
                      if (type.toLowerCase().indexOf("ppt") != -1) {
                        fileInfo.iconType = "ppt";
                      }
                    });
                  }
                });
              }
            });
            this.viewData = res.data.data;
          } else {
            this.viewData = [];
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //获取课程内容目录的内容list
    getData () {
      let params = {}
      if (this.$route.query.id === undefined && this.$route.path.indexOf('courseResource') != -1) {
        params.courseThemeCatalogueId = this.contentId
      } else {
        params.pushInfoId = this.contentId
      }
      this.$api.getEditPublishInfo(params).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data.length > 0) {
            res.data.data.map((item, index) => {
              item.showFlag = true;
              if (item.contentType == "2") {
                // item.activityTypeRadio = this.activityTypeOptions[0].value;
                let activityList = []
                this.activityTypeOptions.map((activityInfo) => {
                  if (item.eduActivity && item.eduActivity.length > 0) {
                    let index = item.eduActivity.findIndex((info) => { return info.activityClassify == activityInfo.value })
                    let listIndex = activityList.findIndex((info) => { return info.value == activityInfo.value })
                    if (index != -1 && listIndex == -1) {
                      activityList.push(activityInfo)
                    }
                  }
                })
                if (item.eduActivity && item.eduActivity.length > 0) {
                  item.activityListShow = true
                } else {
                  item.activityListShow = false
                }
                if (activityList.length > 0) {
                  item.activityTypeRadio = activityList[0].value;
                  item.activityTypeList = activityList
                  this.getCourseContentCatalogActivityList(item.id, activityList[0].value, null, null, item, index, (data) => {
                    if (data) {
                      console.log(this.viewData)
                      let activityInfo = {};
                      activityInfo = { ...item };
                      activityInfo.activityList = data;
                      this.$set(this.viewData, index, activityInfo);
                    }
                  });
                }
              }
              if (item.eduCourseThemeCatalogueContentFile && item.eduCourseThemeCatalogueContentFile.length > 0) {
                item.eduCourseThemeCatalogueContentFile.map((fileInfo) => {
                  let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf("."), fileInfo.fileUrl.length);
                  if (type.toLowerCase().indexOf("pdf") != -1) {
                    fileInfo.iconType = "pdf";
                  }
                  if (type.toLowerCase().indexOf("doc") != -1) {
                    fileInfo.iconType = "doc";
                  }
                  if (type.toLowerCase().indexOf("xls") != -1) {
                    fileInfo.iconType = "xls";
                  }
                  if (type.toLowerCase().indexOf("ppt") != -1) {
                    fileInfo.iconType = "ppt";
                  }
                });
              }
              if (item.eduCourseThemeCatalogueContentTrees && item.eduCourseThemeCatalogueContentTrees.length > 0) {
                item.eduCourseThemeCatalogueContentTrees.map((info, i) => {
                  info.showFlag = true;
                  if (info.contentType == "2") {
                    // info.activityTypeRadio = this.activityTypeOptions[0].value;
                    let activityList = []
                    this.activityTypeOptions.map((activityInfo) => {
                      if (info.eduActivity && info.eduActivity.length > 0) {
                        let index = info.eduActivity.findIndex((result) => { return result.activityClassify == activityInfo.value })
                        let listIndex = activityList.findIndex((result) => { return result.value == activityInfo.value })
                        if (index != -1 && listIndex == -1) {
                          activityList.push(activityInfo)
                        }
                      }
                    })
                    if (info.eduActivity && info.eduActivity.length > 0) {
                      info.activityListShow = true
                    } else {
                      info.activityListShow = false
                    }
                    if (activityList.length > 0) {
                      info.activityTypeRadio = activityList[0].value;
                      info.activityTypeList = activityList
                      this.getCourseContentCatalogActivityList(info.id, this.activityTypeOptions[0].value, info, i, item, index, (data) => {
                        if (data) {
                          let activityInfo = {};
                          activityInfo = { ...info };
                          activityInfo.activityList = data;
                          this.$set(this.viewData[index].eduCourseThemeCatalogueContentTrees, i, activityInfo);
                        }
                      });
                    }
                  }
                  if (info.eduCourseThemeCatalogueContentFile && info.eduCourseThemeCatalogueContentFile.length > 0) {
                    info.eduCourseThemeCatalogueContentFile.map((fileInfo) => {
                      let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf("."), fileInfo.fileUrl.length);
                      if (type.toLowerCase().indexOf("pdf") != -1) {
                        fileInfo.iconType = "pdf";
                      }
                      if (type.toLowerCase().indexOf("doc") != -1) {
                        fileInfo.iconType = "doc";
                      }
                      if (type.toLowerCase().indexOf("xls") != -1) {
                        fileInfo.iconType = "xls";
                      }
                      if (type.toLowerCase().indexOf("ppt") != -1) {
                        fileInfo.iconType = "ppt";
                      }
                    });
                  }
                });
              }
            });
            this.viewData = res.data.data;
          } else {
            this.viewData = [];
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    //获取课程内容目录的活动内容list
    getCourseContentCatalogActivityList (id, type, secondItem, secondIndex, firstItem, firstIndex, callback) {
      this.$api.contentCatalogActivityHandle("/api/techactivity/list", { courseThemeCatalogueContentId: id, activityClassify: type, }, "list").then((res) => {
        if (res.data.code === 0) {

          setTimeout(() => {
            callback && callback(res.data.data);
          }, 300);
        } else {
          this.$message.error(res.data.msg);
          callback && callback(false);
        }
      });
    },
    //内容操作
    handleContentItem (type, secondItem, secondIndex, firstItem, firstIndex) {
      this.secondInfo = secondItem;
      this.secondIndex = secondIndex;
      this.firstInfo = firstItem;
      this.firstIndex = firstIndex;
      switch (type) {
        case "turnItem":
          let info = {};
          if (secondItem) {
            info = { ...secondItem };
            info.showFlag = !info.showFlag;
            this.$set(this.viewData[firstIndex].eduCourseThemeCatalogueContentTrees, secondIndex, info);
          } else {
            info = { ...firstItem };
            info.showFlag = !info.showFlag;
            this.$set(this.viewData, firstIndex, info);
          }
          break;
      }
    },
    //活动切换操作
    handleRadioChange (val, secondItem, secondIndex, firstItem, firstIndex) {
      let info = null;
      if (secondItem) {
        info = { ...secondItem };
        info.activityTypeRadio = val;
        this.$set(this.viewData[firstIndex].eduCourseThemeCatalogueContentTrees, secondIndex, info);
        this.getCourseContentCatalogActivityList(secondItem.id, val, info, secondIndex, firstItem, firstIndex, (data) => {
          if (data) {
            let activityInfo = {};
            activityInfo = { ...info };
            activityInfo.activityList = data;
            this.$set(this.viewData[firstIndex].eduCourseThemeCatalogueContentTrees, secondIndex, activityInfo);
          }
        });
      } else {
        info = { ...firstItem };
        info.activityTypeRadio = val;
        this.$set(this.viewData, firstIndex, info);
        this.getCourseContentCatalogActivityList(firstItem.id, val, secondItem, secondIndex, info, firstIndex, (data) => {
          if (data) {
            console.log(this.viewData)
            let activityInfo = {};
            activityInfo = { ...info };
            activityInfo.activityList = data;
            this.$set(this.viewData, firstIndex, activityInfo);
          }
        });
      }
    },
    // 预览文件
    fileView (fileInfo) {
      // if (type == 'view') {
      let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf('.') + 1, fileInfo.fileUrl.length);
      if (this.fileTypeList.indexOf(type.toLowerCase()) != -1) {
        this.viewUrl = fileInfo.fileUrl;
        this.viewUrlList = [fileInfo.fileUrl];
        this.$nextTick(() => {
          this.$refs.viewImg.showViewer = true;
        });
      } else {
        const { originalName, fileVersionId } = fileInfo
        this.getFileViewUrl('', { fileVersionId, name: originalName }, (data) => {
          if (data) {
            sessionStorage.setItem('viewUrl', data);
            let routeUrl = this.$router.resolve({ path: '/fileView.html' });
            window.open(routeUrl.href, '_blank');
          }
        });
      }
      // } else {
      //   if (fileInfo.isDownload === '1') {
      //     let type = fileInfo.fileUrl.substr(fileInfo.fileUrl.lastIndexOf('.') + 1, fileInfo.fileUrl.length);
      //     if (this.fileTypeList.indexOf(type.toLowerCase()) != -1) {
      //       this.$message.warning('该附件为图片，只可预览查看');
      //     } else {
      //       // window.location.href = fileInfo.fileUrl;
      //       this.exportFile(fileInfo.fileUrl,{},fileInfo.originalName)
      //     }
      //   } else {
      //     this.$message.warning('该附件不支持下载！');
      //   }
      // }
    },
    //视频预览
    videoView (video) {
      this.isViewShow = true
      this.$nextTick(() => {
        this.videoUrl = video.videoUrl
      })
    },
    //视频预览关闭
    handleVideoClose () {
      this.isViewShow = false
      this.$nextTick(() => {
        this.videoUrl = ""
      })
    },
    //弹窗关闭
    handleClose () {
      this.viewData = []
      this.$emit("update:courseViewDialogVisible", false)
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-dialog__body {
  padding: 0;
}
.view-body {
  width: 345px;
  height: 637px;
  margin: 0 auto;
  background-color: #fafafb;
  padding: 15px;
  position: relative;
  .dialog-panel {
    width: calc(100% - 30px);
    padding: 0 15px;
    background-color: #ffffff;
    border-radius: 12px;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    .dialog-panel-title {
      width: 100%;
      height: 53px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name {
        height: 100%;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: #171725;
        .line {
          width: 6px;
          height: 16px;
          background-color: #fed630;
          border-radius: 3.5px;
          margin-right: 5px;
        }
        .text {
        }
      }
      .button {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        img {
          width: 100%;
          height: 100%;
          &.turn-icon-class {
            transform: rotate(180deg);
          }
        }
      }
    }
    .dialog-panel-content {
      width: 100%;
      .content-panel {
        width: 100%;
        padding-top: 15px;
      }
      .dialog-second-panel {
        width: 100%;
        .dialog-panel-second-title {
          font-size: 14px;
          font-weight: 600;
          color: #44444f;
          margin-bottom: 15px;
        }
        .dialog-panel-second-content {
          width: 100%;
        }
      }
      .dialog-content-info {
        width: 100%;
        padding-bottom: 15px;
        .content-html {
          width: 100%;
          font-size: 14px;
          font-weight: 400;
          color: #696974;
          line-height: 1.7;
          /deep/p {
            width: 100%;
            display: block;
            margin-bottom: 10px !important;
          }
          /deep/img {
            border-radius: 12px;
          }
          /deep/audio {
            display: inline-block;
            width: 100%;
            max-width: 100%;
          }
          /deep/table {
            border-top: 1px solid #ccc;
            border-left: 1px solid #ccc;
            margin: 10px 0;
            line-height: 1.5;
            min-height: 100px;
            th {
              border-bottom: 2px solid #ccc;
              border-right: 1px solid #ccc;
              padding: 3px 5px;
              min-height: 30px;
              text-align: center;
              background-color: #f1f1f1;
            }
            td {
              border-bottom: 1px solid #ccc;
              border-right: 1px solid #ccc;
              padding: 3px 5px;
              min-height: 30px;
            }
          }
        }
      }
      .dialog-file-info {
        width: 100%;
        padding-bottom: 15px;
        .dialog-file-item {
          width: calc(100% - 30px);
          padding: 0 15px;
          height: 56px;
          background-color: #fafafb;
          border-radius: 6px;
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0;
          }
          .icon {
            width: 23px;
            height: 26px;
            margin-right: 10px;
          }
          .title {
            font-size: 12px;
            font-weight: 400;
            color: #333333;
            width: calc(100% - 33px);
          }
        }
      }
      .dialog-video-info {
        width: 100%;
        padding-bottom: 15px;
        .dialog-video-item {
          width: 150px;
          height: 112.5px;
          border-radius: 10px;
          position: relative;
          display: inline-block;
          vertical-align: middle;
          .video-bg {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
          .video-play-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 40px;
            height: 40px;
          }
        }
      }
      .dialog-activity-type-check {
        width: 100%;
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        .dialog-activity-type-item {
          width: 76px;
          height: 30px;
          background-color: #f1f1f5;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50px;
          font-size: 12px;
          font-weight: 400;
          color: #92929d;
          margin-right: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:last-child {
            margin-right: 0;
          }
          &.active {
            width: 72px;
            height: 26px;
            border: 2px solid #171725;
            background-color: #fed630;
            font-weight: 500;
            color: #171725;
          }
        }
      }
      .dialog-third-panel {
        width: 100%;
        padding-bottom: 15px;
        .dialog-panel-third-title {
          width: 100%;
          margin-bottom: 8px;
          font-size: 14px;
          font-weight: 400;
          color: #44444f;
        }
        .dialog-panel-third-content {
          width: 100%;
          .content-html {
            font-size: 12px;
            font-weight: 400;
            color: #92929d;
            line-height: 1.7;
            /deep/p {
              width: 100%;
              display: block;
              margin-bottom: 10px !important;
            }
            /deep/img {
              border-radius: 12px;
            }
            /deep/audio {
              display: inline-block;
              width: 100%;
              max-width: 100%;
            }
            /deep/table {
              border-top: 1px solid #ccc;
              border-left: 1px solid #ccc;
              margin: 10px 0;
              line-height: 1.5;
              min-height: 100px;
              th {
                border-bottom: 2px solid #ccc;
                border-right: 1px solid #ccc;
                padding: 3px 5px;
                min-height: 30px;
                text-align: center;
                background-color: #f1f1f1;
              }
              td {
                border-bottom: 1px solid #ccc;
                border-right: 1px solid #ccc;
                padding: 3px 5px;
                min-height: 30px;
              }
            }
          }
        }
      }
    }
  }
}
.video-view {
  position: absolute;
  top: 0;
  left: 0;
  width: 375px;
  height: 651px;
  background-color: #000000;
  padding-top: 16px;
  video {
    width: 100%;
    max-height: 100%;
  }
  .video-view-del-icon {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 16px;
    color: #ffffff;
  }
}
</style>